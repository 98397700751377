import React, { useState } from "react";
import { Header } from "../utils/NameString";
import "../../css/sidebar.css";
import { useNavigate } from "react-router-dom";
import { postMethod } from "../../services/base_service";
import { showAlert } from "../../services/alert_service";
import { Apiurl } from "../../services/apiRoute";
import clipboardCopy from "clipboard-copy";

const Navbar = () => {
  const [isLoading, setIsLoading] = useState(false);
  const code = localStorage.getItem("reference_code");
  const [referralCode, setReferralCode] = useState(code);
  const navigate = useNavigate();
  const handleLogout = async () => {
    await localStorage.clear();
    navigate("/login");
  };

  const handleReferralCode = async () => {
    try {
      setIsLoading(true);
      const response = await postMethod(Apiurl.generate_referral_code);
      setIsLoading(false);
      if (response.data.status === 403) {
        showAlert(response.data.message, "error");
      } else {
        showAlert(response.data.message, "success");
        localStorage.setItem(
          "reference_code",
          response?.data?.reference_code ? response?.data?.reference_code : ""
        );
        setReferralCode(
          response.data?.reference_code ? response.data?.reference_code : ""
        );
      }
    } catch (err) {
      console.log("error:" + err);
      showAlert("Internal Server Error", "error");
    }
  };

  return (
    <div>
      {isLoading ? <span className="loader"></span> : ""}
      <nav className="navbar navbar-fixed-top">
        <div className="container-fluid">
          <div className="navbar-left">
            <div className="navbar-btn">
              <img
                src={Header.Logo_image}
                alt="hospital Logo"
                className="img-fluid logo"
              />
              <button type="button" className="btn-toggle-offcanvas">
                <i className="fa fa-align-left"></i>
              </button>
            </div>
          </div>
          <div className="navbar-right">
            <div id="navbar-menu">
              <ul className="nav navbar-nav">
                <li>
                  <div
                    className="icon-menu"
                    onClick={() =>
                      referralCode
                        ? clipboardCopy(referralCode)
                        : handleReferralCode()
                    }
                  >
                    <button
                      type="button"
                      className="btn btn-default btn-sm"
                      style={{
                        backgroundColor: "#22a63c",
                        borderRadius: "5px",
                        marginRight: "5px",
                        color: "white",
                      }}
                      title="Edit"
                      // onClick={() => setAddModal()}
                    >
                      {referralCode
                        ? "Referral Code - " + referralCode
                        : " Request Referral Code"}
                    </button>
                  </div>
                </li>
                <li>
                  <div className="icon-menu" onClick={handleLogout}>
                    <i className="fa fa-power-off"></i>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </nav>
    </div>
  );
};

export default Navbar;
