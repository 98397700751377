import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import Login from "./components/Auth/Login";
import AccessControl from "./services/AccessControl";
import AccountRequests from "./components/pages/request/AccountRequests";
import Users from "./components/pages/Users";
import NotificationsList from "./components/pages/NotificationsList";
import ReferralList from "./components/pages/ReferralList";
import Dashboard from "./components/pages/Dashboard";

function App() {
  // const isLoggedIn = localStorage.getItem("token");
  // const verifyemail = localStorage.getItem("verifyemail");

  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/login" element={<Login />}></Route>
          <Route element={<AccessControl />}>
            <Route path="/" element={<Dashboard />} />
            <Route path="/users" element={<Users />} />
            <Route path="/account-requests" element={<AccountRequests />} />
            <Route path="/notifications" element={<NotificationsList />} />
            <Route path="/referralslist" element={<ReferralList />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
