import React, { useEffect, useState } from "react";
// import { useForm } from "react-hook-form";
import toast, { Toaster } from "react-hot-toast";
import Navbar from "../Navbar";
import Pagination from "../../../services/Pagination";
import { Modal, ModalHeader } from "reactstrap";
import { Apiurl } from "../../../services/apiRoute";
import { getMethod, putMethod } from "../../../services/base_service";
import { showAlert } from "../../../services/alert_service";

const AccountRequests = () => {
  const [requests, setRequests] = useState([]);
  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [modal, setModal] = useState(false);
  const [terminalId, setTerminalId] = useState(0);
  const [selected, setSelected] = useState(0);
  const [roles, setRoles] = useState([]);
  const [notification, setNotification] = useState({ title: "", body: "" });
  const notify = () => toast(<ToastDisplay />);

  function ToastDisplay() {
    return (
      <div>
        <b>{notification?.title}</b>

        <p>{notification?.body}</p>
      </div>
    );
  }
  // get account request list
  const getAccountRequests = async () => {
    try {
      setIsLoading(true);
      const response = await getMethod(
        `${Apiurl.accountRequest}/?page=1&limit=10&search=`
      );
      console.log("response----------", response.data.data);
      setRequests(response.data.data);
      setTotalPages(response.data.total_page);
      setIsLoading(false);
    } catch (error) {
      console.log("error" + error);
      showAlert("Internal Server Error", "error");
    }
  };

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  // const setEditModal = (terminalId) => {
  //   setModal(true);
  //   const selected = requests.find((item) => item.terminal_id === terminalId);
  //   setTerminalId(terminalId);
  //   setValue("account_status", selected.account_status);
  //   setValue("status", selected.status);
  // };

  const handleUpdateAccount = async (data) => {
    try {
      console.log("data before call", data);
      if (data.status === "approved") {
        data.account_status = "active";
      } else if (data.status === "reject") {
        data.account_status = "deactivate";
      }
      setIsLoading(true);
      const response = await putMethod(
        `${Apiurl.account}/update/${terminalId}`,
        data
      );
      showAlert(response.data.data.message, "success");
      getAccountRequests();
      setIsLoading(false);
      setModal(false);
    } catch (error) {
      console.log("error" + error);
      showAlert("Internal Server Error", "error");
    }
  };

  // onMessageListener()
  //   ?.then((payload) => {
  //     console.log("payload===>", payload);
  //     setNotification({
  //       title: payload?.notification?.title,
  //       body: payload?.notification?.body,
  //     });
  //     localStorage.setItem(
  //       "reference_code",
  //       payload?.data?.reference_code ? payload?.data?.reference_code : ""
  //     );
  //     getAccountRequests();
  //   })
  //   ?.catch((err) => console.log("failed: ", err));

  // async function handleRegistration() {
  //   const registration = await registerAndRequestPermission();
  //   if (registration) {
  //     const token = await getTokenData(registration);
  //     console.log("FCM token:=> ", token);
  //     if (token !== null) {
  //       sendFCMToken(token);
  //     }
  //   }
  // }

  useEffect(() => {
    if (notification?.title) {
      notify();
    }
  }, [notification]);

  // useEffect(() => {
  //   handleRegistration();
  // }, []);

  useEffect(() => {
    getAccountRequests();
  }, [currentPage]);

  const sendFCMToken = async (token) => {
    try {
      const response = await putMethod(Apiurl.fcm_token, {
        firebase_token: token,
      });
    } catch (error) {
      showAlert(error.meaasge, "error");
    }
  };

  return (
    <div>
      <Navbar />

      <div id="main-content">
        <ToastDisplay />
        <div className="container-fluid">
          <div className="block-header">
            <div className="row clearfix">
              <div className="col-lg-4 col-md-12 col-sm-12">
                <h1>Account Requests</h1>
              </div>
            </div>
          </div>
          <div className="row clearfix">
            <div className="col-sm-12">
              {isLoading ? (
                <span className="loader"></span>
              ) : requests.length === 0 ? (
                <p>No Records Found</p>
              ) : (
                <>
                  <div className="table-responsive">
                    <table className="table table-hover table-custom spacing5">
                      <thead>
                        <tr>
                          <th>Sr.No</th>
                          <th>Account Number</th>
                          <th>Server Name</th>
                          <th>USD</th>
                          <th>AUD</th>
                          <th>EUR</th>
                          <th>Status</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {requests.map((record, recordIndex) => {
                          const uniqueCurrencies = Array.from(
                            new Set(
                              record.admin_currencies.map(
                                (currency) => currency.currencies_id
                              )
                            )
                          );
                          return (
                            <tr key={`${recordIndex}`}>
                              <td>{recordIndex + 1}</td>
                              <td>{record?.terminal_id}</td>
                              <td>{record?.t_pc?.name}</td>
                              {uniqueCurrencies.map((currencyId) => (
                                <td key={currencyId}>
                                  {record.admin_currencies
                                    .filter(
                                      (currencyRecord) =>
                                        currencyRecord.currencies_id ===
                                        currencyId
                                    )
                                    .map(
                                      (filteredCurrencyRecord) =>
                                        filteredCurrencyRecord?.count || ""
                                    )}
                                </td>
                              ))}
                              <td>{record?.status}</td>
                              <td>
                                <button
                                  type="button"
                                  className="btn btn-default btn-sm"
                                  title="Edit"
                                  // onClick={() => setEditModal(record.terminal_id)}
                                >
                                  <i className="fa fa-pencil"></i>
                                </button>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                  <Pagination
                    currentPage={currentPage}
                    totalPages={totalPages}
                    onPageChange={handlePageChange}
                  />
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AccountRequests;
