export const Apiurl = {
  // routePath: "http://localhost:3011/api/v1",
  routePath: "https://apiuserstock.genxel.in/api/v1",

  // imageRoutePath: "http://localhost:3011",
  imageRoutePath:"https://apiuserstock.genxel.in",
  login: "/user/login",
  signup: "/user/signup",
  t_pc: "/t_pc/list",
  emailVerify: "/otp/verify",
  resendOtp: "/otp/resend",
  addAccount: "/account/add",
  accountList: "/account/list",
  accountRequest: "/account_request/list",
  currencyList: "/currency/list",
  updateCurrency: "/add_and_update/currency",
  updateCurrencyList: "/currencyList",
  password_request: "/password_request",
  generate_referral_code: "/user/generate_referral_code",
  fcm_token: "/user/fcm_token",
  notification: "/user/notification/list",
  referralList: "/user/referral_list",
};
